import { render, staticRenderFns } from "./MarcusHonkanen.vue?vue&type=template&id=5ce1239e&"
import script from "./MarcusHonkanen.vue?vue&type=script&lang=js&"
export * from "./MarcusHonkanen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports