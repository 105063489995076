<template>
  <div class="columns">
    <div class="column col-8 col-sm-12 col-offset-2 txt--center">
      <h1 class="team__member-headline">
        {{ $t("team.markus_honkanen.name") }}
      </h1>
      <p>{{ $t("team.markus_honkanen.position") }}</p>
    </div>
    <div class="column col-10 col-sm-12 col-offset-1 txt--center">
      <div v-html="$t('team.markus_honkanen.intro')"></div>
    </div>
    <div class="columns col-12">
      <div class="columns columns--quote">
        <div class="column col-5 col-md-12">
          <img
            class="img-responsive profile-photo"
            src="../../../assets/images/team/nm_profile_img_marcus.jpg"
          />
        </div>
        <div class="column col-7 col-md-12">
          <blockquote class="quote">
            {{ $t("team.markus_honkanen.quote") }}
          </blockquote>
          <a
            :href="$t('team.markus_honkanen.contact_data')[0].link"
            class="button button--arrow-left quote__link"
            >{{ $t("team.markus_honkanen.contact_data")[0].label }}</a
          >
        </div>
      </div>
    </div>
    <div class="column col-12">
      <div class="list--boxed list--boxed-left-bottom">
        <ul class="list__item list--dash list--dash-columns">
          <li
            class="list__item"
            v-for="(obj, index) in $t('team.markus_honkanen.cv')"
            :key="index"
          >
            {{ obj }}
          </li>
        </ul>
      </div>
    </div>
    <div class="columns column--full-width-bg contact-box">
      <div class="column col-6 col-md-12">
        <h4>{{ $t("team.fields_headline") }}</h4>
        <ul class="list list--tagged">
          <li
            class="list__item"
            v-for="(obj, index) in $t('team.markus_honkanen.fields')"
            :key="index"
          >
            {{ obj }}
          </li>
        </ul>
      </div>
      <div class="column col-5 col-md-12 col-offset-1 col-md-offset-0">
        <h4>{{ $t("team.contact_headline") }}</h4>
        <ul class="list list--contact">
          <li
            class="list__item"
            v-for="(obj, index) in $t('team.markus_honkanen.contact_data')"
            :key="index"
          >
            <a :href="`${obj.link}`" target="_blank">{{ obj.label }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Honkanen",
};
</script>
